<template>
  <StageTemplate
    title="Mehr Daten - das ist die Wahrheit"
    class="svg"
    :color="color"
    :stage-visual="stageVisual"
    :stage-visual-mobile="stageVisualMobile"
    :href="$router.resolve(path).href"
    :animate-elements="animateElements"
    :svg-view-box-height="600"
  >
  </StageTemplate>
</template>

<script>
import StageTemplate from 'src/components/common/stages/templates/background_title_svg.vue'
import StageVisual from './resources/tariff_upgrade_2024/stagevisual.svg'
import StageVisualMobile from './resources/tariff_upgrade_2024/stagevisual-mobile.svg'

const animationDelay = 0.5
const delayOffset = 0.1

export default {
  name: 'FonicTariffs2024',
  components: {
    StageTemplate
  },
  data() {
    return {
      stageVisual: StageVisual,
      stageVisualMobile: StageVisualMobile,
      color: '#E6BE26',
      path: {
        name: 'PortalTariffOverview'
      },

      animateElements: [
        {
          query: '#Gluehbirnen_mobile',
          animation: 'bulb-glow 1.5s linear 0s 3 forwards',
          calculateElementCenterPoint: true
        }
      ]
    }
  },
  async mounted() {
    let offset = 0

    for (let i = 1; i <= 18; i++) {
      offset += delayOffset
      this.animateElements.push({
        query: `#Brine-${i}`,
        animation: `glow-rotate 1s ease-out ${
          animationDelay + offset
        }s 1 forwards`,
        calculateElementCenterPoint: true
      })
    }
  }
}
</script>

<style lang="scss">
@keyframes glow-rotate {
  0% {
    fill: grey;
    filter: drop-shadow(0 #fff) drop-shadow(0 0 1px #f7e7b0)
      drop-shadow(0 0 2px #f7e7b0);
  }

  20% {
    fill: #f1eee7;
    filter: drop-shadow(0 0 3px #fff) drop-shadow(0 0 4px #f7e7b0)
      drop-shadow(0 0 6px #f7e7b0);
  }

  100% {
    fill: #f1eee7;
    filter: drop-shadow(0 0 2px #fff) drop-shadow(0 0 3px #f7e7b0)
      drop-shadow(0 0 5px #f7e7b0);
  }
}

@keyframes bulb-glow {
  0% {
    filter: drop-shadow(0 #fff) drop-shadow(0 0 1px #f7e7b0)
      drop-shadow(0 0 2px #f7e7b0);
  }

  50% {
    filter: drop-shadow(0 0 3px #fff) drop-shadow(0 0 4px #f7e7b0)
      drop-shadow(0 0 6px #f7e7b0);
  }

  100% {
    filter: drop-shadow(0 #fff) drop-shadow(0 0 1px #f7e7b0)
      drop-shadow(0 0 2px #f7e7b0);
  }
}
</style>

<style lang="scss" scoped>
.svg {
  background: var(--tariff-update);

  :deep(svg) {
    #Schild {
      transform: translateX(-10px) translateY(38px);
    }
    @include breakpoint($up-to-mobile) {
      #Lama_Kopie {
        transform: translateX(-10px);
      }
    }

    .birne {
      fill: grey;
    }
  }
}

:deep(.button) {
  margin-right: 0;
}
</style>
